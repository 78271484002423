import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import queryString, { stringify } from 'query-string';
import { GetData } from '../common';
import history from '../history';

export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cart: [],
            total: 0,
            currency: {name:"USD"}
        }
    }

    componentDidMount() {
        this.loadData();
        this.getTotal();
    }

    loadData = async () => {
        var cart = localStorage.getItem("cart");
        if (cart !== null) {
            cart = JSON.parse(cart);
            this.setState({ cart: cart });
        }

        if(cart.length==0){
            history.push("/");
            window.location=window.location.href;
        }

        var currency = localStorage.getItem("currency");
        if (currency !== null) {
            this.setState({ currency: JSON.parse(currency) });
        }
    }

    changeQuantity = async (index, e) => {
        var cart = localStorage.getItem("cart");
        if (cart !== null) {
            cart = JSON.parse(cart);
            cart[index].quantity = e.target.value;
            localStorage.setItem("cart", JSON.stringify(cart));
            this.setState({ cart: cart });
        }
    }

    getTotal = async () => {
        var currency = localStorage.getItem("currency");
        if (currency !== null) {
            currency=JSON.parse(currency);
            this.setState({ currency: currency });
        }
        console.log(currency);
        
        var total = 0;
        var cart = localStorage.getItem("cart");
        if (cart !== null) {
            cart = JSON.parse(cart);
            for (var c in cart) {
                const cData=cart[c];

                var discount = cData.product.price;
                if(cData.product.discount!==undefined){
                    if (cData.product.discount > 0) {
                        discount = (cData.product.price - ((cData.product.discount / 100) * cData.product.price));
                        discount = discount.toFixed(2);
                    }
                }

                var subttotal=Number(cData.quantity) * Number(discount);
                subttotal=Number(subttotal)*Number(currency.rate);
                total+=subttotal;
                if (cData.delivery != undefined)
                    total += Number(cData.delivery)*Number(currency.rate);
            }
        }
        total=total.toFixed(2);
        this.setState({ total: total });
    }

    removeFromCart = async (ind) => {
        var cart = localStorage.getItem("cart");
        if (cart !== null) {
            cart = JSON.parse(cart);
            cart=cart.filter(function(data,index){
                return index!==ind;
            })
            localStorage.setItem("cart",JSON.stringify(cart));
            
            window.location.reload();
        }
    }

    render() {
        const { currency} = this.state;


        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title">Checkout</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="blog-posts style2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="post-wrap style2">
                                            {(this.state.cart.map((data, index) => {
                                                var img = "";
                                                if (data.product.images != undefined) {
                                                    if (data.product.images[0] != undefined) {
                                                        img = data.product.images[0].image;
                                                    }
                                                }
                                                

                                                var discount = data.product.price;
                                                if(data.product.discount!==undefined){
                                                    if (data.product.discount > 0) {
                                                        discount = (data.product.price - ((data.product.discount / 100) * data.product.price));
                                                        discount = discount.toFixed(2);
                                                    }
                                                }

                                                var price = Number(data.quantity) * Number(discount);

                                                var delivery=Number(data.delivery)*Number(currency.rate);
                                                delivery = delivery.toFixed(2);

                                                var priceConverted = Number(price) * Number(currency.rate);
                                                priceConverted=Number(priceConverted)+Number(delivery);
                                                priceConverted = priceConverted.toFixed(2);

                                                return <article className="post clearfix">
                                                    <div className="featured-post">
                                                        <img src={img} alt="image" width="200" />
                                                    </div>
                                                    <div className="content-post">
                                                        <div className="title-post">
                                                            <h2><a href="#">{data.product.name}</a></h2>
                                                        </div>
                                                        <ul className="meta-post">
                                                            <li className="author">
                                                                <span>Category: </span><a href="#">{data.product.subcategory_name}</a>
                                                            </li>
                                                        </ul>
                                                        <div className="title-post">
                                                            Quantity :  <input type="number" value={data.quantity} style={{ width: 100 }} onChange={(e) => this.changeQuantity(index, e)} />
                                                        </div>
                                                        {(data.delivery != undefined) &&
                                                            <ul className="meta-post">
                                                                <li className="author">
                                                                    <span>Delivery : {currency.name}</span> <a href="#">{delivery}</a>
                                                                </li>
                                                            </ul>}
                                                        <br />
                                                        <div className="title-post">
                                                            <h3><a href="#">{currency.name} {priceConverted}</a></h3>
                                                        </div>
                                                        <div className="title-post">
                                                            <a href="#" onClick={()=>this.removeFromCart(index)}>
                                                                Remove <img src="images/bin.png" width="14"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </article>
                                            }))}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-md-3">
                                        <div className="">
                                            <div className="widget widget-news-latest">
                                                <h5 className="widget-title">Payment</h5>
                                                <ul className="popular-news clearfix">
                                                    <li>
                                                        <h6><a href="#">You will be charged the total amount below for this order …</a></h6>
                                                    </li>
                                                    <li>
                                                        <h3><a href="#">TOTAL : {currency.name} {this.state.total}</a></h3>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="product-detail">
                                                <div className="add-to-cart">
                                                    <a href="/payment" style={{ cursor: 'pointer' }}>CHECKOUT</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}