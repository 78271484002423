import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { GetData, PostData } from '../common';
import history from '../history';

export default class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fname: "",
            lname: "",
            email: "",
            dob: "",
            mobile: "",
            username: "",
            password: "",
            gender: 1,
            country: "",
            city: "",
            state: "",
            zipcode: "",
            address: "",
            errormsg: "",
            loginerrormsg: "",
            loading: false,
            login_username: "",
            login_password: "",
            confirmpassword:""
        }
    }

    componentDidMount() {

    }

    laodData = async => {

    }

    registerAccount = async () => {
        if (this.state.fname == "") {
            this.setState({ errormsg: "First name is required" });
        } else if (this.state.lname == "") {
            this.setState({ errormsg: "Last name is required" });
        } else if (this.state.email == "") {
            this.setState({ errormsg: "Email address is required" });
        } else if (this.state.mobile == "") {
            this.setState({ errormsg: "Mobile number is required" });
        } else if (this.state.dob == "") {
            this.setState({ errormsg: "Date of birth is required" });
        } else if (this.state.country == "") {
            this.setState({ errormsg: "Country is required" });
        } else if (this.state.address == "") {
            this.setState({ errormsg: "Address is required" });
        } else if (this.state.city == "") {
            this.setState({ errormsg: "City is required" });
        } else if (this.state.zipcode == "") {
            this.setState({ errormsg: "Zipcode is required" });
        } else if (this.state.username == "") {
            this.setState({ errormsg: "Username is required" });
        } else if (this.state.password == "") {
            this.setState({ errormsg: "Password is required" });
        } else if(this.state.password!=this.state.confirmpassword){
            this.setState({ errormsg: "Passwords do not match" });
        }else {
            var payload = new URLSearchParams();
            payload.append('first_name', this.state.fname);
            payload.append('last_name', this.state.lname);
            payload.append('email', this.state.email);
            payload.append('mobile', this.state.mobile);
            payload.append('username', this.state.username);
            payload.append('password', this.state.password);
            payload.append('country', this.state.country);
            payload.append('city', this.state.city);
            payload.append('zipcode', this.state.zipcode);
            payload.append('address', this.state.address);
            payload.append('state', "");
            payload.append('gender', this.state.gender);
            payload.append('date_of_birth', this.state.dob);
            payload.append('groups', 2);

            var user = await PostData("users/", payload, false);
            if (user.code == 201) {
                localStorage.setItem("tokenDetails", JSON.stringify({ username: this.state.username, password: this.state.password }));
                localStorage.setItem("customer", JSON.stringify(user.message));

                var formData = new URLSearchParams();
                formData.append("username", this.state.username);
                formData.append("password", this.state.password);

                if (localStorage.getItem("loginNav") == null) {
                    history.push("/");
                } else {
                    history.push(localStorage.getItem("loginNav"));
                }
                window.location=window.location.href;
                localStorage.removeItem("loginNav");

                var getToken = await PostData("api/jwt/token/", formData, false);
                if (getToken.code == 200) {
                    localStorage.setItem("jwtToken", getToken.message.access);
                }

                window.location = window.location.href;
            } else {
                var errors = "";
                for (var e in user.message) {
                    const data = user.message[e];
                    for (var d in data) {
                        errors += `${data[d]}`;
                    }
                }
                this.setState({ errormsg: errors });
            }
            console.log(user);
        }
    }

    loginFunction = async () => {
        if (this.state.login_username == "") {
            this.setState({ loginerrormsg: "Last name is required" });
        } else if (this.state.login_password == "") {
            this.setState({ loginerrormsg: "Last name is required" });
        } else {
            var formData = new URLSearchParams();
            formData.append("username", this.state.login_username);
            formData.append("password", this.state.login_password);
            var getToken = await PostData("api/jwt/token/", formData, false);
            if (getToken.code == 200) {
                localStorage.setItem("jwtToken", getToken.message.access);

                localStorage.setItem("tokenDetails", JSON.stringify({ username: this.state.login_username, password: this.state.login_password }));
                var getUser = await GetData(`users/?username=${this.state.login_username}`);
                console.log("getUser::>>>",getUser);
                localStorage.setItem("customer", JSON.stringify(getUser.message.results[0]));
                
                if (localStorage.getItem("loginNav") == null) {
                    history.push("/");
                } else {
                    history.push(localStorage.getItem("loginNav"));
                }
                window.location=window.location.href;
                localStorage.removeItem("loginNav");
            }else{
                var errors = "";
                for (var e in getToken.message) {
                    const data = getToken.message[e];
                    for (var d in data) {
                        errors += `${data[d]}`;
                    }
                }
                this.setState({ loginerrormsg: errors });
            }
        }
    }

    render() {
        const { loading } = this.state;
        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title"></h1>
                                        </div>
                                        <div className="breadcrumbs">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="flat-row flat-contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="title-section margin_bottom_17">
                                            <h4 className="title">
                                                New Account
                                            </h4>
                                            <p>Fill the form below to create a new account.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="title-section margin_bottom_17">
                                            <h4 className="title">
                                                Login
                                            </h4>
                                            <p>You already have an account with us</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7" style={{ border: '1px solid #ededed', padding: 40 }}>
                                        <strong style={{ color: "#A00" }}>{this.state.errormsg}</strong>
                                        <div className='row registerForm'>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="First Name" aria-required="true" value={this.state.fname} onChange={(e) => this.setState({ fname: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Last Name" aria-required="true" value={this.state.lname} onChange={(e) => this.setState({ lname: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <select defaultValue={this.state.gender} onChange={(e) => this.setState({ gender: e.target.value })}>
                                                        <option value="">Select Gender</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                        <option value="3">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="date" placeholder="Date of birth" aria-required="true" value={this.state.dob} onChange={(e) => this.setState({ dob: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Email Address" aria-required="true" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Username" aria-required="true" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="password" placeholder="Password" aria-required="true" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="password" placeholder="Confirm Password" aria-required="true" value={this.state.confirmpassword} onChange={(e) => this.setState({ confirmpassword: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Address" aria-required="true" value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="City" aria-required="true" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="State" aria-required="true" value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Zipcode" aria-required="true" value={this.state.zipcode} onChange={(e) => this.setState({ zipcode: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <select defaultValue={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}>
                                                        <option value="">Select Country</option>
                                                        <option value="uk">UK</option>
                                                        <option value="usa">USA</option>
                                                        <option value="ghana">Ghana</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Mobile Number" aria-required="true" value={this.state.mobile} onChange={(e) => this.setState({ mobile: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className=" col-md-12 form-submit margin-top-32 " style={{ marginTop: 10 }}>
                                                {(loading == false) ?
                                                    <button className="contact-submit" onClick={() => this.registerAccount()}>Create Account</button> :
                                                    <p>Loading...</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4">
                                        <strong style={{ color: "#A00",marginBottom:20 }}>{this.state.loginerrormsg}</strong>
                                        
                                        <div className="wrap-contact">
                                            <form novalidate="" className="contact-form" id="contactform" method="post" action="#">
                                                <div className="form-text-wrap clearfix">
                                                    <div className="">
                                                        <input type="text" placeholder="Username" aria-required="true" value={this.state.login_username} onChange={(e) => this.setState({ login_username: e.target.value })} />
                                                    </div>
                                                    <br />
                                                    <div className="">
                                                        <input type="password" placeholder="Password" value={this.state.login_password} onChange={(e) => this.setState({ login_password: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-submit margin-top-32 ">
                                                    <a href="/reset">Forgotten your password?</a>
                                                </div>
                                                <div className="form-submit margin-top-32 ">
                                                    <button type="button" className="contact-submit" onClick={() => this.loginFunction()}>LOGIN</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text">
                                            <h3>Sign up for Send Newsletter</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}