import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { GetData } from '../common';
import Parser from 'html-react-parser';
import queryString from 'query-string';
import Spinner from '../components/Spinner';
; 
const domain=window.location.hostname;
const env_=(domain==="taussh.com")?"live":"test";
const authkey = (env_==="live")?process.env.REACT_APP_AUTH_LIVE:process.env.REACT_APP_AUTH;
const base = (env_==="live")?process.env.REACT_APP_PAYMENT_BASE_LIVE:process.env.REACT_APP_PAYMENT_BASE;
const paystack = (env_==="live")?process.env.REACT_APP_PAYSTACK_SECRET_LIVE:process.env.REACT_APP_PAYSTACK_SECRET;
const successurl=(env_==="live")?process.env.REACT_APP_PAYMENT_SUCCESS_URL_LIVE:process.env.REACT_APP_PAYMENT_SUCCESS_URL;

export default class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: null,
            status: "",
            label: ""
        }
    }

    componentDidMount() {
        this.laodData();
    }

    laodData = async () => {
        localStorage.removeItem("cart");
        var trxref = queryString.parse(this.props.location.search).trxref;
        var payerId = queryString.parse(this.props.location.search).PayerID;
        if(trxref!==undefined){
            var contentSlides = await GetData(`paystack/?reference=${trxref}`);
            console.log("----->",contentSlides);
            // if (contentSlides.code == 200) {
            //     this.setState({ content: contentSlides.message.results[0]});
            // }

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer sk_test_08542fe254be78869d86ac0396aab30e4de7172d");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var self = this;
            await fetch(`https://api.paystack.co/transaction/verify/${trxref}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    self.setState({ status: result.data.status, label: (result.data.status == "success") ? "Congratulations" : "Payment Failed" });
                })
                .catch(error => {
                    self.setState({ status: "failed", label: "Payment Failed" });
                });
        }
        if(payerId!==undefined){
            this.authorizeOrder(queryString.parse(this.props.location.search).token);
        }

        if(queryString.parse(this.props.location.search).token!==undefined){
            this.setState({ status: "failed", label: "Payment Failed" });
        }
    }

    authorizeOrder=async(order_id)=>{
        var self=this;
        await fetch(`${base}/v1/oauth2/token`, {
            method: "post",
            body: "grant_type=client_credentials",
            headers: {
                Authorization: `Basic ${authkey}`,
            },
        }).then(response => response.json())
            .then(result => {
                var accessToken = result.access_token;
                self.authorizeOrder2(accessToken,order_id);
            })
            .catch(error => console.log('error', error));;
    }

    authorizeOrder2=async(accessToken,order_id)=>{
        var self=this;
        fetch(`${base}/v2/checkout/orders/${order_id}/capture`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        }).then(response => response.json())
            .then(result => {
                var success=false;
                console.log("result::::::",result);
                if(result.status!==undefined)
                    if(result.status=="COMPLETED")
                        success=true;

                if(result.details!=undefined)
                    if(result.details[0]!=undefined)
                        if(result.details[0].issue=="ORDER_ALREADY_CAPTURED")
                            success=true;

                if(success==true)
                    self.setState({ status: "success", label: "Congratulations"});
                else
                    self.setState({ status: "failed", label: "Payment Failed" });
            })
            .catch(error => console.log('error', error));;
    }

    render() {
        const { status, label } = this.state;

        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            {(status == "") && <Spinner bg={"#f7f7f7"} fill={"#000"} />}
                                            {(status != "") &&
                                                <h1 className="title">{label}</h1>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section className="blog-posts">
                            <div className="container">
                                <div className="row">
                                    {(status!=="")&&
                                    <div className="col-md-12 text-center">
                                        <img src={(status == "success")?"images/success.png":"images/failed.png"}/>
                                        <div className="post-wrap ">
                                            {(status == "success")?<p>Your payment was successful.<br/>Thank you for your business we will notify you on processing and delivery.</p>:
                                            <p>Sorry the payment failed, kindly click the button below to retry again</p>}
                                        </div>
                                        <div className="add-to-cart">
                                                <a href={`/orders`} style={{background:'#222',padding:'20px 30px',color:'#fff',fontWeight:'bold'}}>View All Your Orders</a>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}