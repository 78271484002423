import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { GetData, PostData } from '../common';
import history from '../history';
import Spinner from '../components/Spinner';
import queryString from 'query-string';

export default class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fname: "",
            lname: "",
            email: "",
            dob: "",
            mobile: "",
            username: "",
            password: "",
            gender: 1,
            country: "",
            city: "",
            state: "",
            zipcode: "",
            address: "",
            errormsg: "",
            loginerrormsg: "",
            loading: false,
            login_username: "",
            login_password: "",
            confirm_password: "",
            resetmodal: "",
            loading: false
        }
    }

    componentDidMount() {
        this.laodData();
    }

    laodData = async => {
        var id = queryString.parse(this.props.location.search).token;
        if (id == undefined || id == "")
            this.setState({ resetmodal: false });
        else
            this.setState({ resetmodal: true });
    }

    registerAccount = async () => {
        if (this.state.fname == "") {
            this.setState({ errormsg: "First name is required" });
        } else if (this.state.lname == "") {
            this.setState({ errormsg: "Last name is required" });
        } else if (this.state.email == "") {
            this.setState({ errormsg: "Email address is required" });
        } else if (this.state.mobile == "") {
            this.setState({ errormsg: "Mobile number is required" });
        } else if (this.state.dob == "") {
            this.setState({ errormsg: "Date of birth is required" });
        } else if (this.state.country == "") {
            this.setState({ errormsg: "Country is required" });
        } else if (this.state.address == "") {
            this.setState({ errormsg: "Address is required" });
        } else if (this.state.city == "") {
            this.setState({ errormsg: "City is required" });
        } else if (this.state.zipcode == "") {
            this.setState({ errormsg: "Zipcode is required" });
        } else if (this.state.username == "") {
            this.setState({ errormsg: "Username is required" });
        } else if (this.state.password == "") {
            this.setState({ errormsg: "Password is required" });
        } else if (this.state.password != this.state.confirmpassword) {
            this.setState({ errormsg: "Passwords do not match" });
        } else {
            var payload = new URLSearchParams();
            payload.append('first_name', this.state.fname);
            payload.append('last_name', this.state.lname);
            payload.append('email', this.state.email);
            payload.append('mobile', this.state.mobile);
            payload.append('username', this.state.username);
            payload.append('password', this.state.password);
            payload.append('country', this.state.country);
            payload.append('city', this.state.city);
            payload.append('zipcode', this.state.zipcode);
            payload.append('address', this.state.address);
            payload.append('state', "");
            payload.append('gender', this.state.gender);
            payload.append('date_of_birth', this.state.dob);
            payload.append('groups', 2);

            var user = await PostData("users/", payload, false);
            if (user.code == 201) {
                localStorage.setItem("tokenDetails", JSON.stringify({ username: this.state.username, password: this.state.password }));
                localStorage.setItem("customer", JSON.stringify(user.message));

                var formData = new URLSearchParams();
                formData.append("username", this.state.username);
                formData.append("password", this.state.password);

                if (localStorage.getItem("loginNav") == null) {
                    history.push("/");
                } else {
                    history.push(localStorage.getItem("loginNav"));
                }
                window.location = window.location.href;
                localStorage.removeItem("loginNav");

                var getToken = await PostData("api/jwt/token/", formData, false);
                if (getToken.code == 200) {
                    localStorage.setItem("jwtToken", getToken.message.access);
                }

                window.location = window.location.href;
            } else {
                var errors = "";
                for (var e in user.message) {
                    const data = user.message[e];
                    for (var d in data) {
                        errors += `${data[d]}`;
                    }
                }
                this.setState({ errormsg: errors });
            }
            console.log(user);
        }
    }

    loginFunction = async () => {
        if (this.state.login_username == "") {
            this.setState({ loginerrormsg: "Email address is required" });
        } else {
            this.setState({ loading: true });
            var formData = new URLSearchParams();
            formData.append("email", this.state.login_username);
            var getToken = await PostData("password_reset/", formData, false);
            console.log("getToken:::", getToken);
            if (getToken.code == 200) {
                this.setState({ loginerrormsg: "Reset request successful, kindly check your and click on the link." });
            } else {
                var errors = "";
                for (var e in getToken.message) {
                    const data = getToken.message[e];
                    for (var d in data) {
                        errors += `${data[d]}`;
                    }
                }
                this.setState({ loginerrormsg: errors });
            }
            this.setState({ loading: false });
        }
    }

    resetFunction = async () => {
        if (this.state.password == "") {
            this.setState({ loginerrormsg: "Password is required" });
        }else if (this.state.password !== this.state.confirm_password) {
            this.setState({ loginerrormsg: "Password do not match" });
        } else {
            this.setState({ loading: true });
            var formData = new URLSearchParams();
            formData.append("token", queryString.parse(this.props.location.search).token);
            formData.append("password", this.state.password);
            
            var getToken = await PostData("password_reset/confirm/", formData, false);
            console.log("getToken:::", getToken); 
            if (getToken.code == 200) {
                this.setState({ loginerrormsg: "Password reset successful, kindly proceed to login" });
            } else {
                var errors = "";
                for (var e in getToken.message) {
                    const data = getToken.message[e];
                    for (var d in data) {
                        errors += `${data[d]}`;
                    }
                }
                this.setState({ loginerrormsg: errors });
            }
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, resetmodal } = this.state;
        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title"></h1>
                                        </div>
                                        <div className="breadcrumbs">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="flat-row flat-contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title-section margin_bottom_17">
                                            <h4 className="title">
                                            {(resetmodal==false) ?"Password Recovery":"Password Reset"}
                                            </h4>
                                            <p>Fill the form below to continue.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="title-section margin_bottom_17">

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <strong style={{ color: "#A00", marginBottom: 20 }}>{this.state.loginerrormsg}</strong>


                                        <div className="wrap-contact">
                                            {(resetmodal==false) ?
                                                <form novalidate="" className="contact-form" id="contactform" method="post" action="#">
                                                    <div className="form-text-wrap clearfix">
                                                        <div className="">
                                                            <input type="text" placeholder="Email Address" aria-required="true" value={this.state.login_username} onChange={(e) => this.setState({ login_username: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    {(loading == false) ?
                                                        <div className="form-submit margin-top-32 ">
                                                            <button type="button" className="contact-submit" onClick={() => this.loginFunction()}>SUBMIT</button>
                                                        </div> : <Spinner bg={"#fff"} fill={"#000"} />}
                                                </form> :
                                                <form novalidate="" className="contact-form" id="contactform" method="post" action="#">
                                                    <div className="form-text-wrap clearfix">
                                                        <div className="">
                                                            <input type="password" placeholder="Password" aria-required="true" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div className="form-text-wrap clearfix">
                                                        <div className="">
                                                            <input type="password" placeholder="Confirm Password" aria-required="true" value={this.state.confirm_password} onChange={(e) => this.setState({ confirm_password: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    {(loading == false) ?
                                                        <div className="form-submit margin-top-32 ">
                                                            <button type="button" className="contact-submit" onClick={() => this.resetFunction()}>SUBMIT</button>
                                                        </div> : <Spinner bg={"#fff"} fill={"#000"} />}
                                                </form>}
                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5" style={{ border: '1px solid #fff', padding: 40 }}>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text">
                                            <h3>Sign up for Send Newsletter</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}