import { Component } from "react";

export default class Footer extends Component {
    render() {
        return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-3">
                                <div className="widget widget-link">
                                    <ul>
                                        <li><a href="/about">About Us</a></li>
                                        <li><a href="/catalog">Catalogue</a></li>
                                        <li><a href="/contact">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="widget widget-link link-login">
                                    <ul>
                                        <li><a href="/login">Login/ Register</a></li>
                                        <li><a href="/checkout">Your Cart</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="widget widget-link link-faq">
                                    <ul>
                                        <li><a href="/faq">FAQs</a></li>
                                        <li><a href="/terms">Term of service</a></li>
                                        <li><a href="/policy">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="widget widget-brand">
                                    <div className="logo logo-footer">
                                        <a href="/">
                                            <img src="images/logo@2x.png" alt="image" width="107" height="24" /></a>
                                    </div>
                                    <ul className="flat-contact">
                                        <li className="address"> Unit 17 Silens Works, 29 Peckover Street, Bradford, BD1 5BD. UK</li>
                                        <li className="phone">+44 1274 738 555</li>
                                        <li className="phone">+233 20 669 3347</li>
                                        <li className="email">info@taussh.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="copyright text-center">© www.taussh.com All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <a className="go-top">
                    <i className="fa fa-chevron-up"></i>
                </a>
            </>
        )
    }
}