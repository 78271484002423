import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import queryString from 'query-string';
import { currency, GetData } from '../common';
import Spinner from '../components/Spinner';

export default class Catalog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            menu: {},
            currency: {name:"USD"},
            next:"",
            previous:"",
            loading:false
        }
    }

    componentDidMount() {
        this.getProducts("");
        this.loadData();
    }

    loadData = async => {
        var currency = localStorage.getItem("currency");
        if (currency !== null) {
            this.setState({ currency: JSON.parse(currency) });
        }
    }

    getProducts = async (url) => {
        this.setState({loading:true});
        var params = queryString.parse(this.props.location.search);
        var getmenu = { code: 400 };

        if (url !="") {
            if(url.startsWith("http")){
                var http=url.split("?");
                console.log(http);
            }
            console.log("params:::", params);
            getmenu = await GetData(`products/?${http[1]}`);
        }
        else if (params.category != undefined) {
            console.log("params:::", params);
            getmenu = await GetData(`products/?status=true&category=${params.category}`);

            // var menu=getmenu = await GetData(`category/${params.menu}/`);
            // this.setState({ menu: menu.message });
        } else if (params.subcategory != undefined) {
            console.log("params:::", params);
            getmenu = await GetData(`products/?status=true&subcategory=${params.subcategory}`);

            // var menu=getmenu = await GetData(`subcategory/${params.menu}/`);
            // this.setState({ menu: menu.message });
        } else if (params.menu != undefined) {
            console.log("params:::", params);
            getmenu = await GetData(`products/?status=true&menu=${params.menu}`);

            // var menu=getmenu = await GetData(`menus/${params.menu}/`);
            // this.setState({ menu: menu.message });
        } else {
            getmenu = await GetData("products/?status=true");
            this.setState({ menu: { name: "Catalog" } });
        }

        if (getmenu.message.code == "token_not_valid") {
            localStorage.removeItem("jwtToken");
            if(url =="")
                this.getProducts();
            else
                this.getProducts(url);
        }

        console.log(getmenu);
        if (getmenu.code == 200) {
            this.setState({ products: getmenu.message.results });

            this.setState({next:(getmenu.message.next==null)?"":getmenu.message.next});
            this.setState({previous:(getmenu.message.previous==null)?"":getmenu.message.previous});
        }

        //Menu 
        if (params.category != undefined) {
            var menu = getmenu = await GetData(`categories/${params.category}/`);
            this.setState({ menu: menu.message });
        } else if (params.subcategory != undefined) {
            var menu = getmenu = await GetData(`subcategories/${params.subcategory}/`);
            this.setState({ menu: menu.message });
        } else if (params.menu != undefined) {
            var menu = getmenu = await GetData(`menu/${params.menu}/`);
            this.setState({ menu: menu.message });
        } else {
            this.setState({ menu: { name: "Catalog" } });
        }

        window.scrollTo(0, 0);
        this.setState({loading:false});
    }

    render() {
        const { products, menu,currency,loading } = this.state;
        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        {loading==true &&<center><Spinner bg="#fff" fill="#222"/></center>}

                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title">{menu.name}</h1>
                                            <div className="breadcrumbs">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section className="flat-row main-shop">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="product-content product-fivecolumn clearfix">
                                                <ul className="product style3">

                                                    {products.map((data) => {
                                                        var img = "";
                                                        if (data.images.length > 0)
                                                            img = data.images[0].image;

                                                        var discount = 0;
                                                        if (data.discount > 0) {
                                                            discount = (data.price - ((data.discount / 100) * data.price));
                                                            discount = discount.toFixed(2);
                                                        }

                                                        var price_ = (data.discount > 0) ? discount : data.price;
                                                        var priceConverted = Number(price_) * Number(currency.rate);
                                                        var Normalprice = Number(data.price) * Number(currency.rate);
                                                        Normalprice = Normalprice.toFixed(2);
                                                        priceConverted = priceConverted.toFixed(2);

                                                        return <li className="product-item" style={{maxHeight:470}}>
                                                            <div className="product-thumb clearfix" style={{height:370,overflow:'hidden',marginBottom:10,background:'#fff'}}>
                                                                <a href={"/details?id=" + data.id}>
                                                                    <img src={img} alt="image" height="80" />
                                                                </a>
                                                                {(data.discount > 0) && <span className="new sale" style={{ width: 'auto', padding: '0px 10px' }}>{data.discount}% Sale</span>}
                                                            </div>
                                                            <div className="product-info clearfix">
                                                                <p className="product-title" style={{ height: 50 }}>{data.name}</p>
                                                                <div className="price">
                                                                    {(data.discount > 0) && <del style={{ color: '#444', marginRight: 5 }}><span className="regular" style={{ color: '#444', fontWeight: 'bold', fontSize: 10 }}>{currency.name} {Normalprice}</span></del>}
                                                                    <ins>
                                                                        <span className="amount">{currency.name} {priceConverted}</span>
                                                                    </ins>
                                                                </div>
                                                            </div>
                                                            <div className="add-to-cart text-center">
                                                                <a href={"/details?id=" + data.id}>MORE</a>
                                                            </div>
                                                        </li>;
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="product-pagination text-center clearfix">
                                                <ul className="flat-pagination">
                                                    <li className="prev">
                                                        <a style={{cursor:'pointer'}} onClick={()=>{
                                                            if(this.state.previous!=="")
                                                            this.getProducts(this.state.previous);
                                                        }}><i className="fa fa-angle-left"></i></a>
                                                    </li>
                                                    <li>
                                                        <a style={{cursor:'pointer'}} onClick={()=>{
                                                            if(this.state.next!=="")
                                                            this.getProducts(this.state.next);
                                                        }}><i className="fa fa-angle-right"></i></a>
                                                    </li>
                                                </ul>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}