import logo from './logo.svg';
import './App.css';
import history from './history';
import Index from './pages/Index';
import About from './pages/About';
import Contact from './pages/Contact';
import Catalog from './pages/Catalog';
import Details from './pages/Details';
import Login from './pages/Login';
import Terms from './pages/Terms';
import Policy from './pages/Policy';
import Faq from './pages/Faq';
import Checkout from './pages/Checkout';
import Payment from './pages/Payment';
import Order from './pages/Order';
import Blogs from './pages/Blogs';
import BlogsDetails from './pages/BlogsDetails';
import SuccessPage from './pages/SuccessPage';
import OrderDetails from './pages/OrderDetails';
import Profile from './pages/Profile';
import Reset from './pages/Reset';

import Custmomization from './pages/Customization';

import {BrowserRouter as Router, Switch,Route} from 'react-router-dom';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" render={props=><Index {...props}/>}/>
        <Route exact path="/about" render={props=><About {...props}/>}/>
        <Route exact path="/catalog" render={props=><Catalog {...props}/>}/>
        <Route exact path="/contact" render={props=><Contact {...props}/>}/>
        <Route exact path="/details" render={props=><Details {...props}/>}/>
        <Route exact path="/login" render={props=><Login {...props}/>}/>
        <Route exact path="/policy" render={props=><Policy {...props}/>}/>
        <Route exact path="/terms" render={props=><Terms {...props}/>}/>
        <Route exact path="/faq" render={props=><Faq {...props}/>}/>
        <Route exact path="/orders" render={props=><Order {...props}/>}/>
        <Route exact path="/payment" render={props=><Payment {...props}/>}/>
        <Route exact path="/checkout" render={props=><Checkout {...props}/>}/>
        <Route exact path="/selection" render={props=><Custmomization {...props}/>}/>
        <Route exact path="/blogs" render={props=><Blogs {...props}/>}/>
        <Route exact path="/blogsdetails" render={props=><BlogsDetails {...props}/>}/>
        <Route exact path="/success" render={props=><SuccessPage {...props}/>}/>
        <Route exact path="/orderdetails" render={props=><OrderDetails {...props}/>}/>
        <Route exact path="/profile" render={props=><Profile {...props}/>}/>
        <Route exact path="/reset" render={props=><Reset {...props}/>}/>
        <Route exact path="/password_reset/confirm" render={props=><Reset {...props}/>}/>
      </Switch>
    </Router>
  );
}

export default App;
