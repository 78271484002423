import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { GetData } from '../common';
import Parser from 'html-react-parser';

export default class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: [],
            next:"",
            previous:""
        }
    }

    componentDidMount() {
        this.laodData("");
    }

    laodData = async (url) => {
        if (url !="") {
            if(url.startsWith("http")){
                var http=url.split("?");
            }
            var contentSlides = await GetData(`blogs/?${http[1]}`);
            if (contentSlides.code == 200) {
                this.setState({ content: contentSlides.message.results });
            }
        }else{
            var contentSlides = await GetData("blogs/?status=true");
            if (contentSlides.code == 200) {
                this.setState({ content: contentSlides.message.results });
            }
        }
    }

    render() {
        const {content}=this.state;
        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title">Blog</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section className="blog-posts grid-posts">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="post-wrap margin-bottom-26">
                                            <div className="grid three">
                                                {content.map(data => {
                                                    const string2 = data.content.substring(0, 100).concat('...');
                                                    return <article className="post clearfix"  style={{height:400}}>
                                                        <div className="featured-post" style={{height:200,overflow:'hidden'}}>
                                                            {(data.image.endsWith(".png") || data.image.endsWith(".jpg") || data.image.endsWith(".jpeg") || data.image.endsWith(".gif"))?
                                                            <img src={data.image} alt="image" />:
                                                            <video controls width={"100%"} height="200">
                                                                <source src={data.image} type="video/mp4"></source>
                                                            </video>}
                                                        </div>
                                                        <div className="content-post">
                                                            <div className="title-post">
                                                                <h2><a href={`/blogsdetails?id=${data.id}`}>{data.title}</a></h2>
                                                            </div>
                                                            <div className="entry-post">
                                                                {Parser(string2)}    
                                                                <div className="more-link">
                                                                    <a href={`/blogsdetails?id=${data.id}`}>Read More</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                })}
                                            </div>
                                        </div>
                                        <div className="product-pagination text-center clearfix">
                                                <ul className="flat-pagination">
                                                    <li className="prev">
                                                        <a style={{cursor:'pointer'}} onClick={()=>{
                                                            if(this.state.previous!=="")
                                                            this.laodData(this.state.previous);
                                                        }}><i className="fa fa-angle-left"></i></a>
                                                    </li>
                                                    <li>
                                                        <a style={{cursor:'pointer'}} onClick={()=>{
                                                            if(this.state.next!=="")
                                                            this.laodData(this.state.next);
                                                        }}><i className="fa fa-angle-right"></i></a>
                                                    </li>
                                                </ul>
                                                
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">

                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}